@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body,
#app {
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    color: #fff;
    background-color: #2f2f2f;
    &.active {
        margin-left: -250px;
    }
    a {
        color: #fff;
    }
}

.card {
    margin-bottom: 30px;
}

.table thead th {
    white-space: nowrap;
}

.table tr td button {
    white-space: nowrap;
}

#content {
    width: 100%;
    margin-top: 65px;
    overflow-x: hidden;
}


/* The Modal (background) */

.wp-modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}


/* Modal Content */

.wp-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 5px;
}


/* Add Animation */

@-webkit-keyframes animatetop {
    from {
        // top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        // top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}


/* The Close Button */

.wp-modal-close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.wp-modal-close:hover,
.wp-modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.wp-modal-header {
    padding: 10px 16px;
    color: #000;
    margin-top: 5px;
}

.wp-modal-body {
    padding: 2px 16px 16px;
}

.wp-modal-footer {
    padding: 10px 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.requestloading {
    position: fixed;
    top: 2em;
    right: 2em !important;
    width: 3em;
    height: 3em;
    z-index: 99999;
}

.appshadow {
    -webkit-box-shadow: -1px 1px 5px 1px rgba(185, 185, 185, 0.75);
    -moz-box-shadow: -1px 1px 5px 1px rgba(185, 185, 185, 0.75);
    box-shadow: -1px 1px 5px 1px rgba(185, 185, 185, 0.75);
}

.card-header {
    background-color: whitesmoke !important;
    color: black !important;
}

.ql-snow .ql-tooltip {
  position: initial!important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  overflow-y: auto;
  max-height: 10rem;
}

.ql-editor {
  min-height: 175px;
  max-height: 50vh;
  overflow: auto;
}

.important {
  text-align: center;
  color: #3085d6;
  font-weight: 600;
  text-decoration: underline;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.swal-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swal-label {
  display: flex;
  align-items: baseline;
  text-align: left;
  padding-left: 0.5rem;
}

.m-l-15 {
  margin-left: 15px;
}
